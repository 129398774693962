<template>
  <div>
    <div class="content">
      <h2 class="d-flex d-flex--v-center page__text">
        <span class="logo">
          <span>RK</span>
        </span>
        <span class="page__hide-mobile">EGE.REZNIKOVK.RU</span>
        <span class="fs-14 mh-20 page__hide-mobile">➡️</span>
        <span class="page__title page__text">{{ title }}</span>
      </h2>

      <div class="grid grid--main grid--mobile">
        <div class="only-mobile">
          <slot name="sidebar" />
          <MenuMain />
        </div>
        <div class="content__main my-scroll">
          <div style="padding-right: 5px">
            <slot />
          </div>
        </div>
        <div>
          <slot name="sidebar" />
          <MenuMain class="only-desc" />
          <MenuContact />
          <PageInfo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuMain from "@/components/parts/Menu";
import MenuContact from "@/components/parts/MenuContact";
import PageInfo from "@/components/parts/PageInfo";

export default {
  name: 'PageLayout',
  components: {
    MenuMain,
    MenuContact,
    PageInfo
  },
  props: {
    title: {
      type: String,
      default: 'Главная'
    }
  }
}
</script>

<style scoped lang="less">
.logo {
  border-radius: 50%;
  overflow: hidden;
  color: white;
  background: linear-gradient(314deg, #0085ff 0%, #0fd300 100%);
  width: 60px;
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 86px;
  font-weight: 400;
}

.content {
  box-sizing: border-box;

  margin: 0 auto;
  padding: 20px 34px;
  min-height: 100vh;
  max-width: 1200px;
  width: 100%;

  @media screen and (max-width: 960px) {
    padding: 8px 10px;
  }

  &__main {
    max-height: calc(100vh - 120px);
    overflow: auto;

    @media screen and (max-width: 960px) {
      max-height: none;
    }
  }
}

.page {

  &__title {
    text-transform: uppercase;
  }

  &__text {

    @media screen and (max-width: 960px) {
      font-size: 22px;
    }
  }

  &__hide-mobile {

    @media screen and (max-width: 960px) {
      display: none;
    }
  }
}

.grid {
  display: grid;
  grid-gap: 24px;

  &--main {
    grid-template-columns: 1fr 260px;
  }

  &--mobile {

    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
