<template>
  <PageLayout title="ОБ АВТОРЕ">
    <section class="p-16 d-flex d-flex--mobile" style="gap: 20px">
      <div class="only-mobile">
        <div class="d-flex-column">
          <div class="ava__container">
            <img class="ava" src="@/assets/ava.jpeg" alt="Фото">
          </div>
          <div class="text-center pathes">
            Бакалавриат (2015-2019) <br>
            Магистратура (2019-2021) <br>
            Аспирантура (2021-2025) <br>
            <br>
            Менторство (2023-н.в.) <br>
            Преподавание (2021-н.в.) <br>
            Репетиторство (2016-н.в.) <br>
            <br>
            Fullstack-разработчик (2017-н.в.)
          </div>
        </div>
      </div>

      <div class="flex-1">
        <h4>
          Резников Константин Георгиевич
        </h4>
        <h5>
          Fullstack-разработчик, программист, IT-специалист.
        </h5>
        <p class="fs-12">
          Главный специалист по разработке в одной из ведущих компаний страны с опытом более 8-ми лет. <br>
          Преподаватель ВУЗа по разработке и программированию с опытом более 4-х лет. <br>
          Частный репетитор по информатике и программированию для подготовки к ЕГЭ, ОГЭ, школьной программы и студентов начальных курсов с опытом более 10-ти лет.<br>
          Ментор, наставник по IT-карьере и разработке с опытом более 3-х лет. <br>
          Разработчик методических пособий для ВУЗов и методик обучения. <br>
        </p>
        <h5>
          Учебная и научная деятельность
        </h5>
        <p class="fs-12">
          В 2015 году закончил 11 классов в обычной школе Тамбовской области. Сдав ЕГЭ, поступил на бюджет в Воронежский государственный университет, где окончил бакалавриат в 2019 году и тогда же продолжил обучение в магистратуре. В 2021 году защитил магистерскую диссертацию по теме “Разработка веб-приложения для визуализации трехмерных поверхностей”. С 2021 года является преподавателем в ВУЗе и продолжает обучение в аспирантуре. Ведет научную деятельность в области разработки и готовит кандидатскую диссертацию. Частные занятия и репетиторскую деятельность осуществляет с 2016 года, начав преподавать еще будучи студентом бакалавриата.
        </p>
        <h5>
          Разработка и программирование
        </h5>
        <p class="fs-12">
          Программированием начал увлекаться еще в школе, а с компьютером и техникой “на ты” совсем с раннего детства. Профессионально начал заниматься разработкой с 2016 года. Тогда еще просто разрабатывая небольшие решения и проекты, которые уже выходили за рамки обычных лабораторных работ в университете. С 2017 года занимается коммерческой разработкой в масштабных проектах, сотрудничая с крупными заказчиками, в том числе - иностранными.
        </p>
      </div>

      <div>
        <div class="d-flex-column">
          <div class="ava__container only-desc">
            <img class="ava" src="@/assets/ava.jpeg" alt="Фото">
          </div>
          <div class="text-center pathes only-desc">
            Бакалавриат (2015-2019) <br>
            Магистратура (2019-2021) <br>
            Аспирантура (2021-2025) <br>
            <br>
            Менторство (2023-н.в.) <br>
            Преподавание (2021-н.в.) <br>
            Репетиторство (2016-н.в.) <br>
            <br>
            Fullstack-разработчик (2017-н.в.)
          </div>

          <div class="text-center pathes" style="margin-top: 20px">
            <a href="https://reznikovk.ru/" target="_blank">
              <button class="btn-prime">Личный сайт</button>
            </a>
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'

export default {
  name: 'AboutPage',
  components: {
    PageLayout
  }
}
</script>

<style lang="less">
.ava {
  width: 100%;

  &__container {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    box-sizing: border-box;
    margin-bottom: 36px;
  }
}
.pathes {
  font-size: 10px;
  font-weight: 700;
}
</style>
