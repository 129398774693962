<template>
  <div>
    <div class="d-flex-center mb-12 d-flex--mobile">
      <h3 class="no-margin">{{ title || (($route.name === 'EGE_THEORY') ? 'Теория' : 'Задание') + ' ' + $route.params.id }}</h3>
      <div class="d-flex-center gap-12 d-flex--mobile">
        <div
          class="badge badge--b"
          :title="'Время чтения материала: ' + timeText">
          🕒 {{ timeText }}
        </div>
        <div class="badge badge--y">Изучено <input v-model="done" type="checkbox"></div>
        <slot name="actions" >
          <RouterLink :to="{ name: $route.name === 'EGE_THEORY' ? 'EGE_TASK' : 'EGE_THEORY', params: { id: $route.params.id } }">
            <button class="btn-prime">{{ $route.name === 'EGE_THEORY' ? 'ЗАДАНИЯ' : 'ТЕОРИЯ' }}</button>
          </RouterLink>
        </slot>
      </div>
    </div>

    <div v-if="isOpened">
      <slot v-if="$slots.default" />
      <div v-else>Скоро будет</div>
    </div>
    <div v-else>
      <div class="closed-task">
        <div class="closed-task__icon">
          <LockIcon color="#ffffff" />
        </div>
        <div class="closed-task__msg">
          Доступ закрыт
        </div>
        <button class="btn-prime" @click="() => openTask()">
          Открыть доступ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { helpModal } from "@/mixins/modals";
import LockIcon from "@/components/icons/Lock";

export default {
  name: "TaskContainer",
  components: {
    LockIcon
  },
  mixins: [helpModal],
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    timeText: {
      type: String,
      default: 'Закрыто'
    },
    pinKey: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('ege', [
      'getActivated',
      'getActivatedSpecialAll',
      'getDone'
    ]),
    isOpened () {
      return this.opened || this.getActivated[this.code] || this.getActivatedSpecialAll
    },
    done: {
      get () {
        return this.getDone(this.pinKey)
      },
      set () {
        this.setDone(this.pinKey)
      }
    }
  },
  methods: {
    ...mapMutations('ege', [
      'setDone'
    ]),
    openTask () {
      this.openOpenTaskModal()
    }
  }
}
</script>

<style scoped lang="less">
.closed-task {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__icon {
    width: 48px;
    height: 48px;
    background: #ff5959;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__msg {
    color: #252525;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  &__btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 6px;
    border: 1px solid #c2e7b0;
    background: #f0f9eb;
    color: #67c23a;
    border-radius: 5px;
    font-size: 16px;

    &:hover {
      background: #67c23a;
      color: white;
      border-color: #67c23a;
    }
  }
}
</style>
