<template>
  <section class="p-16">
    <div class="text-center">
      <div>📞</div>
      <div class="contacts__header">Контакты</div>
      <div class="contacts">
        <a href="https://t.me/reznikovkg" target="_blank">
          <div class="contact-link contact-link--tg">
            <TgIcon color="#28a0da" /> @reznikovkg
          </div>
        </a>
        <a href="https://vk.com/reznikovkg" target="_blank">
          <div class="contact-link contact-link--vk">
            <VkIcon color="#0277ff" /> @reznikovkg
          </div>
        </a>
        <a href="mailto:rkg@reznikovk.ru">
          <div class="contact-link contact-link--email">
            <EmailIcon color="#2a3239" /> rkg@reznikovk.ru
          </div>
        </a>
        <a href="https://forms.gle/Usrq4u62JGiQ21Tk6" target="_blank">
          <div class="contact-link">
            <MessageIcon /> Отправить сообщение
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import TgIcon from './../icons/Tg'
import VkIcon from './../icons/Vk'
import EmailIcon from './../icons/Email'
import MessageIcon from './../icons/Message'

export default {
  name: "MenuContact",
  components: {
    TgIcon,
    VkIcon,
    EmailIcon,
    MessageIcon
  }
}
</script>

<style lang="less">
.contacts {
  font-weight: 700;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  &__header {
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 700;
    color: #5c5a5a;
  }
}

.contact-link {
  color: black;
  padding: 8px 0;
  text-align: center;
  background: #dddddd;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  &--tg {
    background: #e4f6ff;
    color: #0f6893;
  }

  &--vk {
    background: #d7e8fb;
    color: #295ba7;
  }

  &--email {
    background: #e0e6ea;
    color: #2a3239;
  }

  &--gh {
    background: #e6eaeb;
  }
}
</style>
