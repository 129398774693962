import Vue from 'vue';
import VueRouter from 'vue-router';
import { RouteNames } from './routes';
import HomePage from '../components/pages/Home.vue';
import EgePage from '../components/pages/Ege.vue';
import TaskPage from '../components/pages/Task.vue';
import TheoryPage from '../components/pages/Theory.vue';
import AboutPage from '../components/pages/About.vue';
import RepPage from '../components/pages/Rep.vue';
import PythonPage from '../components/pages/Python.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: RouteNames.HOME,
        component: HomePage,
        meta: {
            title: 'Подготовка | Резников Константин'
        }
    },
    {
        path: '/ege',
        name: RouteNames.EGE,
        component: EgePage,
        meta: {
            title: 'ЕГЭ Информатика | Резников Константин'
        }
    },
    {
        path: '/task/:id',
        name: RouteNames.EGE_TASK,
        component: TaskPage,
        meta: {
            title: 'Задача | Резников Константин'
        }
    },
    {
        path: '/theory/:id',
        name: RouteNames.EGE_THEORY,
        component: TheoryPage,
        meta: {
            title: 'Теория | Резников Константин'
        }
    },
    {
        path: '/rep',
        name: RouteNames.REP,
        component: RepPage,
        meta: {
            title: 'Репетитор | Резников Константин'
        }
    },
    {
        path: '/about',
        name: RouteNames.ABOUT,
        component: AboutPage,
        meta: {
            title: 'Об авторе | Резников Константин'
        }
    },
    {
        path: '/python',
        name: RouteNames.PYTHON,
        component: PythonPage,
        meta: {
            title: 'Python | Резников Константин'
        }
    },
];
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});
router.afterEach((to) => {
    document.title = to.meta?.title || 'О, привет! | Резников Константин';
});
export default router;
