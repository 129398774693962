<template>
  <ModalComponent>
    <div class="d-flex modal__header">
      <h4>
        Связаться с Резниковым К. Г.
      </h4>
      <div @click="() => $emit('close')" class="modal__close">
        <CloseIcon />
      </div>
    </div>
    <div class="modal__contact">
      <MenuContact />
    </div>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/parts/Modal";
import MenuContact from "@/components/parts/MenuContact";
import CloseIcon from "@/components/icons/Close";

export default {
  name: "OpenTaskModal",
  components: {
    ModalComponent,
    CloseIcon,
    MenuContact
  },
  props: {
    params: Object
  },
  methods: {
    click (btn) {
      if (btn.click) {
        this.clickHandler(btn.click)
      }

      if (btn.afterClick) {
        this.clickHandler(btn.afterClick)
      }
    },
    clickHandler (click) {
      if (typeof click === 'string') {
        if (click === 'emitClose') {
          return this.$emit('close')
        }
      }

      return click()
    }
  }
}
</script>

<style scoped lang="less">
.modal__contact {
  padding: 0 16px 16px;
}

.grid-price {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-column-gap: 10px;

  &__name {

  }

  &__value {
    text-align: right;
    font-weight: bold;
  }
}


.modal__close {
  cursor: pointer;
}

.modal__header {
  justify-content: space-between;
  padding: 12px;
  min-width: 380px;

  @media screen and (max-width: 960px) {
    min-width: 240px;
  }
}

.modal__content {
  padding: 0 12px 12px;
  display: flex;
  align-items: flex-end;
  gap: 12px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: stretch;
  }
}
</style>
