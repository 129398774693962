<template>
  <PageLayout title="РЕПЕТИТОР">
    <section class="p-16">
      <h4>
        Индивидуальные и групповые занятия Резникова К. Г.
      </h4>
      <div class="d-flex text-with-btn d-flex--mobile d-flex--gap">
        <span>
          Информатика и программирование
        </span>
        <button class="btn-prime" @click="() => $root.openRepModal()">Записаться на занятие</button>
      </div>
      <p>
        Набор учеников осуществляется все время. Занятия проводятся во время учебного года с сентября по июнь.<br>
        Предлагается подготовка для школьников (5-11 классы) и студентов начальных курсов. <br>
        Школьная программа, подготовка к <b>ОГЭ</b>, <b>ЕГЭ</b>, контрольным и прочим экзаменам.
      </p>
      <p>
        Занятия проводятся <b>онлайн</b> в удобное для вас время и по предварительному согласованию с репетитором при наличии мест. <br>
        Возможны <b>очные занятия в г. Воронеж</b> в Ленинском районе.
      </p>
      <p>
        Занятия длятся <b>1,5-3 часа</b>. <br>
      </p>
      <h5>Индивидуальные, частные:</h5>
      <div class="products">
        <Product title="Первое занятие" time="1,5 часа" price="4 000" />
        <Product title="Занятие" time="1,5 часа" price="5 000" />
        <Product title="Занятие" time="2 часа" price="6 000" />
        <Product title="Занятие" time="2,5 часа" price="7 000" />
        <Product title="Занятие" time="3 часа" price="8 000" />
      </div>
      <h5>Групповые занятия 2-4 человека:</h5>
      <div class="products">
        <Product title="2 человека" time="1,5 часа" price="6 000" sub="3 000 рублей на одного" />
        <Product title="3 человека" time="1,5 часа" price="7 500" sub="2 500 рублей на одного" />
        <Product title="4 человека" time="1,5 часа" price="9 000" sub="2 250 рублей на одного" />
      </div>
      <h5>Языки для изучения:</h5>
      <p>
        Python, Pascal, C, C++, C#, QBASIC, PHP, JavaScript, Java.
      </p>
      <h5>Дополнительно:</h5>
      <p>
        Также, возможна профессиональная и современная подготовка по созданию сайтов и веб-приложений (Front, Back и Fullstack).
      </p>
      <p>
        По поводу менторства больше информации можно найти тут - <a href="https://mentor.reznikovk.ru/" target="_blank">mentor.reznikovk.ru</a>
      </p>
      <p>
        Решение задач по программированию 5 000 рублей / час. Дается предварительная оценка по времени. Предоплата 100%.
      </p>
      <div class="d-flex text-with-btn d-flex--mobile d-flex--gap">
        <span>
          По возникшим вопросам обратитесь за консультацией
        </span>
        <button class="btn-prime" @click="() => $root.openRepModal()">Получить консультацию</button>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'
import { helpModal } from "@/mixins/modals";
import Product from "@/components/parts/Product";

export default {
  name: 'AboutPage',
  mixins: [helpModal],
  components: {
    PageLayout,
    Product
  }
}
</script>

<style lang="less">
.products {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 16px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
}
.text-with-btn {
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}
</style>
