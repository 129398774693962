<template>
  <PageLayout title="Python">
    <section class="p-16">
      <h4>
        Python. Занятие 1.
      </h4>
      <h5 class="mt-50">1. Установка Python</h5>
      <p><b>Python 3</b> - <a href="https://www.python.org/downloads/" target="_blank">скачать</a></p>
      <p>Компилятор языка Python, который собирает по исходному коду программу.</p>
      <p><b>PyCharm</b> - <a href="https://jetbrains.com/ru-ru/pycharm/download/" target="_blank">скачать</a></p>
      <p>IDE PyCharm - современный редактор кода и среда разработки.</p>

      <h5 class="mt-50">2. Базовые элементы</h5>
      <p class="mt-50"><b>2.1.</b> Функция печати <b>print</b></p>

      <pre v-highlightjs><code class="python">print("Привет, Мир!")</code></pre>

      <p>Программа напечатает <b>Привет, Мир!</b></p>

      <p class="mt-50"><b>2.2.</b> Различное использование <b>print</b></p>

      <pre v-highlightjs><code class="python">print("Один", "Два", "Три")</code></pre>

      <p>Программа напечатает <b>Один Два Три</b></p>

      <p class="mt-50"><b>2.3.</b> Дополнительные параметры <b>print</b></p>

      <pre v-highlightjs><code class="python">print("Один", "Два", "Три", sep="--")</code></pre>

      <p>Программа напечатает <b>Один--Два--Три</b></p>

      <pre v-highlightjs><code class="python">print("Один", "Два", "Три", sep="--", end="--")
print("Один", "Два", "Три", sep="--", end="")</code></pre>

      <p>Программа напечатает <b>Один--Два--Три--Один--Два--Три</b></p>

      <p class="mt-50"><b>2.4.</b> Параметры по умолчанию <b>print</b></p>
      <p>Разделитель <b>sep=" "</b></p>
      <p>Конец строки <b>end="\n"</b></p>

      <h5 class="mt-50">3. Специальные символы</h5>
      <p>Конец строки с переходом на новую строку - <b>\n</b></p>
      <p>Табуляция - <b>\t</b></p>
      <p>Одинарные и двойный кавычки - <b>\' \"</b></p>

      <pre v-highlightjs><code class="python">print("\tОдин")</code></pre>
      <p><b>&nbsp; &nbsp; &nbsp; &nbsp; Один</b></p>

      <h5 class="mt-50">4. Печать текста</h5>
      <pre v-highlightjs><code class="python">print("""Привет,

Мир!""")</code></pre>
      <p>Программа напечатает:</p>
      <p><b>Привет,</b></p>
      <p><b>Мир!</b></p>


      <h5 class="mt-50">5. Комментарии</h5>
      <pre v-highlightjs><code class="python">print("Один")
# print("Два")
print("Три")</code></pre>

      <p>Программа напечатает:</p>
      <p><b>Один</b></p>
      <p><b>Три</b></p>


      <h4 class="mt-50">
        Python. Занятие 2.
      </h4>

      <div class="d-flex text-with-btn d-flex--mobile d-flex--gap">
        <span>
          Доступ закрыт! Вам доступно только 1 из 40 занятий.
        </span>
        <button class="btn-prime" @click="() => $root.openOpenTaskModal()">Открыть доступ</button>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'

export default {
  name: 'HomePage',
  components: {
    PageLayout
  }
}
</script>

<style lang="less">
.language-python {
  font-size: 16px;
}

.mt-50 {
  margin-top: 50px;
}
.ml-32 {
  margin-left: 32px;
}
</style>
