import { render, staticRenderFns } from "./MenuContact.vue?vue&type=template&id=7169f04e"
import script from "./MenuContact.vue?vue&type=script&lang=js"
export * from "./MenuContact.vue?vue&type=script&lang=js"
import style0 from "./MenuContact.vue?vue&type=style&index=0&id=7169f04e&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports