<template>
  <ModalComponent>
    <div class="d-flex modal__header">
      <h4>
        Покупка доступа
      </h4>
      <div @click="() => $emit('close')" class="modal__close">
        <CloseIcon />
      </div>
    </div>
    <div class="d-flex modal__content d-flex-mobile">
      <div>
        <h3 class="text-center">Прайс-лист разборов заданий и теории</h3>
        <div class="grid-price">
          <div class="grid-price__value">1 990 рублей</div>
          <div class="grid-price__name">Разбор одного типа задания на выбор</div>

          <div class="grid-price__value">19 990 рублей</div>
          <div class="grid-price__name">Все разборы заданий</div>
        </div>
        <br>
        <div class="grid-price">
          <div class="grid-price__value">9 990 рублей</div>
          <div class="grid-price__name">Теория по информатике</div>

          <div class="grid-price__value">27 990 рублей</div>
          <div class="grid-price__name">Теория по программированию на Python</div>

          <div class="grid-price__value">29 990 рублей</div>
          <div class="grid-price__name">Вся теория (информатика + программирование на Python)</div>
        </div>
        <br>
        <div class="grid-price">
          <div class="grid-price__value">36 990 рублей</div>
          <div class="grid-price__name">Все разборы и теория (полный доступ)</div>
        </div>
        <br>
        <div class="text-center">
          Для покупки свяжитесь с администратором <br> любым удобным для вас способом ➡️
        </div>
      </div>

      <MenuContact style="width: 260px" />
    </div>
  </ModalComponent>
</template>

<script>
import ModalComponent from "@/components/parts/Modal";
import MenuContact from "@/components/parts/MenuContact";
import CloseIcon from "@/components/icons/Close";

export default {
  name: "OpenTaskModal",
  components: {
    ModalComponent,
    CloseIcon,
    MenuContact
  },
  props: {
    params: Object
  },
  methods: {
    click (btn) {
      if (btn.click) {
        this.clickHandler(btn.click)
      }

      if (btn.afterClick) {
        this.clickHandler(btn.afterClick)
      }
    },
    clickHandler (click) {
      if (typeof click === 'string') {
        if (click === 'emitClose') {
          return this.$emit('close')
        }
      }

      return click()
    }
  }
}
</script>

<style scoped lang="less">
.grid-price {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-column-gap: 10px;

  @media screen and (max-width: 960px) {
    grid-template-columns: 130px 1fr;
  }

  &__name {
    word-break: break-word;
  }

  &__value {
    text-align: right;
    font-weight: bold;
  }
}

.modal__close {
  cursor: pointer;
}

.modal__header {
  justify-content: space-between;
  padding: 12px;
  min-width: 480px;

  @media screen and (max-width: 960px) {
    min-width: 220px;
  }
}

.modal__content {
  padding: 0 12px 12px;
  display: flex;
  align-items: flex-end;
  gap: 12px;
  justify-content: space-between;
  max-height: 80vh;
  overflow-y: auto;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
}
</style>
